@font-face {
  font-family: 'Orque';
  src: url('Orque.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: "liga" 1, "calt" 1, "dlig" 1, "ss07" 1, "ss08" 1, "zero" 1, "tnum" 1, "cv03" 1, "cv04" 1, "cv01" 1, "cv09", "cv02" 1;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}